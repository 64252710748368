<template>
  <div class="customerInquiry">
    <div v-if="inquiry != null">
      <button @click="this.$router.push('/dashboard/inquiries')" class="button is-outlined is-info">Back</button>
      <hr>
      <h1 class="title">Customer Inquiry</h1>
      <div class="columns is-multiline">
        <div class="column is-2">
          <div class="field">
            <label class="label">Name</label>
            <h2 class="is-size-4">{{inquiry.name}}</h2>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <label class="label">Company</label>
            <h2 class="is-size-4">{{inquiry.company || "-"}}</h2>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="field">
            <label class="label">Email</label>
            <h2 class="is-size-4">{{inquiry.email || "-"}}</h2>
          </div>
        </div>
        <div class="column is-one-fifth">
          <div class="field">
            <label class="label">Phone</label>
            <h2 class="is-size-4">{{inquiry.phone || "-"}}</h2>
          </div>
        </div>
        <div class="column is-one-fifth">
          <div class="field">
            <label class="label">Submitted</label>
            <h2 class="is-size-4">{{inquiry.submitted_on || "-"}}</h2>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label">Message</label>
            <h2 class="is-size-5">{{inquiry.message || "-"}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Loading...
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Customer Inquiry',
  mounted: function() {
    this.$store.dispatch("CustomerInquiries/setInquiries").then(this.setInquiry)
  },
  data() {
    return {
      inquiry: null
    }
  },
  methods: {
    setInquiry() {
      var inquiry = this.getForm(this.uid)
      console.log("INQ", inquiry)
      this.inquiry = inquiry
    }
  },
  computed: {
    fields() {
      var fields = []
      var objectKeys = Object.keys(this.inquiry)

      objectKeys.forEach(key => {
        if(key != "_id" && key != "date" && key != "First Name" && key != "Last Name" && key != "uid") {
          fields.push({
            label: key,
            value: this.inquiry[key]
          })
        }
      })

      return fields
    },
    uid() {
      return this.$route.params.uid
    },
    ...mapGetters({
      getForm: "CustomerInquiries/getInquiryByUID"
    }),
    name() {
      var name = ""
      var inquiry = this.inquiry
      if(inquiry["First Name"]) {
        name += inquiry["First Name"] + " "
      }

      if(inquiry["Last Name"]) {
        name += inquiry["Last Name"]
      }

      if(inquiry["Name"]) {
        name = inquiry["Name"]
      }

      return name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());


    }
  }
}
</script>
<style scoped>
  .field {
    margin-bottom: 25px;
  }
</style>

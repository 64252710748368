<template>
  <div class="mailingList">
    <div class="columns">
      <div class="column">
        <h1 class="title">Mailing List</h1>
      </div>
      <div class="column" style="display: flex; align-items: center; justify-content: flex-end;">
        <button class="button" @click="addContactModalOpen = true">Add Contact</button>
      </div>
    </div>

    <div class="field">
      <label class="label">Search</label>
      <input type="text" class="input" placeholder="Search for a contact..." v-model="query">
    </div>
    <table class="table is-fullwidth">
      <thead>
        <th>Company</th>
        <th>Contact</th>
        <th>Address</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" :key="contact.objectID">
          <td>{{contact.business_name}}</td>
          <td>{{contact.contact_name}}</td>
          <td>{{contact.address_line_1}} {{contact.city}}, {{contact.state}} {{contact.zip}}</td>
          <td><button class="button is-small" @click="promptAlert(contact)"><i class="fa fa-trash"></i></button></td>
        </tr>
      </tbody>
    </table>
    <AddContactModal :open="addContactModalOpen" @close="addContactModalOpen = false"></AddContactModal>
    <AlertYesNo :open="alertModalOpen" :message="alertMessage" @onConfirm="deleteTargetContact" @onCancel="alertModalOpen = false" @close="alertModalOpen = false"></AlertYesNo>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import AddContactModal from "@/components/dashboard/admin/mailing/AddContactModal"
import AlertYesNo from "@/components/global/AlertYesNo"

export default {
  name: 'Mailing List',
  data() {
    return {
      addContactModalOpen: false,
      alertModalOpen: false,
      targetContact: null,
      alertMessage: "",
      query: "",
      awaitingSearch: false
    }
  },
  components: {
    AddContactModal,
    AlertYesNo
  },
  methods: {
    promptAlert(contact) {
      this.alertMessage = "Remove " + (contact.contact_name) + " from the mailing list?"
      this.targetContact = contact
      this.alertModalOpen = true
    },
    deleteTargetContact() {
      this.alertModalOpen = false
      this.$store.dispatch("MailingList/deleteContact", this.targetContact)
    },
    fetchResults() {
      this.$store.dispatch("MailingList/search", this.query)

    }
  },
  mounted: function() {
  },
  watch: {
    query: function() {
         if (!this.awaitingSearch) {
            setTimeout(() => {
              this.fetchResults();
              this.awaitingSearch = false;
            }, 2000); // 1 sec delay
          }
        this.awaitingSearch = true;
    }
  },
  computed: {
    ...mapGetters({
      contacts: "MailingList/getContacts"
    })
  }
}
</script>

<template>
  <div class="addContactModal">
    <div :class="'modal ' + (open? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Contact</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label>Company/Organization</label>
            <input type="text" class="input" v-model="business_name">
          </div>
          <div class="field">
            <label>Contact Name</label>
            <input type="text" class="input" v-model="contact_name">
          </div>
          <div class="field">
            <label>Address Line 1</label>
            <input type="text" class="input" v-model="address_line_1">
          </div>
          <div class="field">
            <label>Address Line 2</label>
            <input type="text" class="input" v-model="address_line_2">
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label>City</label>
                <input type="text" class="input" v-model="city">
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label>State</label>
                <div class="select">
                  <select v-model="state">
                    <option :value="statee" v-for="(statee, i) in states" :key="i">{{statee}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label>Zipcode</label>
                <input type="text" class="input" v-model="zip">
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="addContact" :disabled="canSubmit">Add Contact</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Add Contact Modal',
  props: {
    open: Boolean
  },
  data() {
    return {
      business_name: "",
      contact_name: "",
      address_line_1: "",
      address_line_2: "",
      state: "",
      city: "",
      zip: "",
      states: ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']
    }
  },
  computed: {
    canSubmit() {
      return !((this.business_name.length > 0 || this.contact_name.length > 0) && this.address_line_1.length > 0 && this.state.length > 0 && this.city.length > 0 && this.zip.length > 4)
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    addContact() {
      this.$store.dispatch("MailingList/addContact", {
        business_name: this.business_name,
        contact_name: this.contact_name,
        address_line_1: this.address_line_1,
        address_line_2: this.address_line_2,
        city: this.city,
        state: this.state,
        zip: this.zip,
        active: true
      }).then(this.close).catch(error => {
        console.log("Error adding contact to mailing list", error)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

<template>
  <div class="customerInquiries">
    <h1 class="title">Customer Inquiries</h1>
    <table class="table is-fullwidth">
      <thead>
        <th>Date</th>
        <th>Form Type</th>
        <th>Customer Name</th>
        <th>Email Address</th>
        <th>Message</th>
      </thead>
      <tbody>
        <tr @click="$router.push('/dashboard/inquiries/' + form.uid)" v-for="form in forms">
          <td>{{getDate(form)}}</td>
          <td>{{getFormName(form)}}</td>
          <td>{{form.name}}</td>
          <td>{{form.email}}</td>
          <td>{{form.message? form.message.substr(0, 150) : ""}}{{(form.message && form.message.length > 150)? "..." : ""}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Helpers from "@/api/Helpers"

export default {
  name: 'Customer Inquires',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      forms: "CustomerInquiries/getInquiries"
    }),
    
  },
  methods: {
    getDate(form) {
      var date_options = { month: 'long', day: 'numeric' }
      var now = new Date()

      if(form.date.getFullYear() != now.getFullYear()) {
        date_options.year = "numeric"
      }

      return form.date.toLocaleDateString("en-US", date_options)
    },
    getFormName(form) {
      return this.toTitleCase(form.form_name.replace(/-/g, ' ')) 
    },
    toTitleCase(str) {
      return Helpers.string.toTitleCase(str)
    }
  },
  mounted: function() {
    this.$store.dispatch("CustomerInquiries/setInquiries")
  }
}
</script>
<style scoped>
  tr {
    transition: .25s ease;
    cursor: pointer
  }

  tr:hover {
    transform: scale(1.01);
    filter: brightness(120%)
  }
</style>

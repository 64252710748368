<template>
  <div class="bugReportCard">
    <div class="tag is-primary">
      {{bug.resolved? "Closed" : "Open"}}
    </div>
    <div class="field">
      <p>
        {{bug.message}}
      </p>
    </div>
        <h2><small>Bug #{{bug.uid}} submitted by {{bug.submitted_by}}</small></h2>

  </div>
</template>

<script>
export default {
  name: 'Bug Report Card',
  props: {
    bug: Object
  }
}
</script>
<style scoped>
  .bugReportCard {
    border: 1px solid #ccc;
    padding: 15px;
  }
</style>


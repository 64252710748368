<template>
  <div class="pricingProfileInfoModal">
    <div :class="'modal ' + (isActive? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Profile Details</p>
          <button class="delete" @click="onClose" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <input type="text" class="input" v-model="profile.name">
              </div>
            </div>
            <div class="column">
              <div class="field ">
                <label class="label">Discount</label>

                <div class="field-body">
                    <div class="field has-addons">

                      <p class="control">
                        <input :class="'input ' + (discountError? 'is-danger': '')" v-model="profile.discount">
                      </p>
                      <p class="control">
                        <a class="button is-static">
                          %
                        </a>
                      </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <label class="label">Users</label>
            <div class="users">
              <input type="text" class="input" placeholder="Search for a user...">
              <div class="user" v-for="user in profile.users">
                {{user}}
              </div>
            </div>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success">Save</button>
          <button class="button" @click="onClose">Close</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pricing Profile Info Modal',
  props: {
    isActive: Boolean,
    profile: Object

  },
  methods: {
    onClose() {
      this.$emit("close")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

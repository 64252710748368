<template>
  <div class="pricingProfiles" v-if="profiles && profiles.length > 0">
    <div class="columns">
      <div class="column">
        <h1 class="title">Pricing Profiles</h1>
      </div>
      <div class="column is-flex is-justify-content-end">
          <button class="button" @click="newProfileModalOpen = true">Create New</button>
      </div>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <th>Title</th>
        <th>Discount</th>
        <th></th>
      </thead>
      <tbody>
        <tr @click="viewProfile(profile)" v-for="profile in profiles" :key="profile.uid">
          <td>{{profile.name}}</td>
          <td>{{profile.discount}}%</td>
          <td>{{profile.users.length}} member{{profile.users.length == 1? "" : "s"}}</td>
        </tr>
      </tbody>
    </table>
    <NewPricingProfileModal :isActive="newProfileModalOpen" @close="newProfileModalOpen = false"></NewPricingProfileModal>
    <PricingProfileInfoModal :profile="targetProfile" :isActive="profileDetailsModalOpen" @close="profileDetailsModalOpen = false"></PricingProfileInfoModal>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import NewPricingProfileModal from "@/components/dashboard/pricing/NewPricingProfileModal"
import PricingProfileInfoModal from "@/components/dashboard/pricing/PricingProfileInfoModal"

export default {
  name: 'Pricing Profiles',
  data() {
    return {
      newProfileModalOpen: false,
      targetProfile: {},
      profileDetailsModalOpen: false
    }
  },
  methods: {
    viewProfile(profile) {
      this.targetProfile = profile
      this.profileDetailsModalOpen = true
    }
  },
  components: {
    NewPricingProfileModal,
    PricingProfileInfoModal
  },
  computed: {
    ...mapGetters({
      profiles: "PricingProfiles/getProfiles"
    })
  },
  mounted: function() {
    this.$store.dispatch("PricingProfiles/setProfiles").then(() => {
      console.log("Profiles", this.profiles)
      this.targetProfile = this.profiles[0]
    })
  }
}
</script>
<style scoped>
  tr {
    cursor: pointer;
  }

  tr:hover {
    background-color: #eee;
  }
</style>

<template>
  <div class="newPricingProfileModal">
    <div :class="'modal ' + (isActive? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">New Pricing Profile</p>
          <button class="delete" @click="onClose" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="field column">
              <label class="label">Name</label>
              <input type="text" class="input" v-model="name">
            </div>
      
            <div class="field column">
              <label class="label">Discount</label>

              <div class="field-body">
                  <div class="field has-addons">

                    <p class="control">
                      <input :class="'input ' + (discountError? 'is-danger': '')" v-model="discount">
                    </p>
                    <p class="control">
                      <a class="button is-static">
                        %
                      </a>
                    </p>

                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="createPricingProfile" :disabled="!canCreate" :class="'button is-success ' + (loading? 'is-loading': '')">Create</button>
          <button @click="onClose" class="button">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'New Pricing Profile Modal',
  props: {
    isActive: Boolean
  },
  methods: {
    createPricingProfile() {
      this.loading = true
      this.$store.dispatch("PricingProfiles/createProfile", {
        name: this.name,
        discount: this.discount,
        users: []
      }).then(this.onClose)
    },
    onClose() {
      this.name = ""
      this.discount = ""
      this.$emit("close")
    }
  },
  computed: {
    canCreate() {
      return this.name.length > 2 && this.discount > 0 && this.discount <= 100
    }
  },
  data() {
    return {
      name: "",
      discount: "",
      discountError: false,
      loading: false
    }
  },
  watch: {
    "discount": function() {
      console.log(this.discount)
      this.discount = Number((this.discount + "").replace(/[^0-9.]/g, ''))

      var dis = this.discount

      if(dis < 0 || dis > 100) {
        this.discountError = true
      } else {
        this.discountError = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

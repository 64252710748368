<template>
  <div class="areYouSure">
    <div :class="'modal ' + (open? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Are You Sure?</p>
          <button class="delete" aria-label="close" @click="onCancel"></button>
        </header>
        <section class="modal-card-body">
          {{message}}
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirm">Confirm</button>
          <button class="button" @click="onCancel">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Are You Sure',
  props: {
    open: Boolean,
    message: String
  },
  methods: {
    onConfirm() {
      this.$emit("onConfirm")
      this.$emit("close")
    },
    onCancel() {
      this.$emit("onCancel")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

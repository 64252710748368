<template>
  <div class="bugReports">
    <h1 class="title">Bug Reports</h1>
    <ul v-for="bug in bugs">
      <BugReportCard :bug="bug"></BugReportCard>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BugReportCard from "@/components/dashboard/bugs/BugReportCard"

export default {
  name: 'Bug Reports',
  components: {
    BugReportCard
  },
  computed: {
    ...mapGetters({
      bugs: "BugReports/getBugs"
    })
  },
  mounted: function() {
    this.$store.dispatch("BugReports/setBugs")
  }
}
</script>
